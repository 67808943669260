import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, InputGroup, DropdownButton, Dropdown, Alert, Overlay, Popover, Collapse } from 'react-bootstrap';
import { FaList, FaChevronDown, FaChevronRight, FaWhatsapp, FaRegCopy, FaTrash } from 'react-icons/fa';
import ReactGA from 'react-ga4';
import './App.css';
function App() {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'hi'); // Get language from localStorage or default to 'hi'
    const [localization, setLocalization] = useState({});
    const [measureUnits, setMeasureUnits] = useState({});
    const [itemNames, setItemNames] = useState({});
    const [categories, setCategories] = useState({});
    const [collapsedCategories, setCollapsedCategories] = useState({});
    const [showListClearedAlert, setShowListClearedAlert] = useState(false);
    const [showListCopiedAlert, setShowListCopiedAlert] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [target, setTarget] = useState(null);
    const [brandNames, setBrandNames] = useState({});
    const [brandGroups, setBrandGroups] = useState({});
    //flags for features to enable/disable
    const [showBrandDropdown, setShowBrandDropdown] = useState(true); // Flag to control brand dropdown
    const [enableSearch, setEnableSearch] = useState(false); // Flag to enable/disable search feature
    const [enableWhatsAppFooter, setEnableWhatsAppFooter] = useState(true); // Flag to enable/disable whatsapp footer

    const ref = useRef(null);
    const [showGuide, setShowGuide] = useState(false); // Control the visibility of the guide
    const [stepIndex, setStepIndex] = useState(0); // Track the current step
    const steps = [
        { target: "#language-dropdown", content: "Select your preferred language here" },
        { target: "#input-group-dropdown-1", content: "Choose the measure unit here" },
        { target: ".fa-list", content: "View your list of selected items here" }
    ];

    useEffect(() => {
        // Initialize Google Analytics with your tracking ID
        ReactGA.initialize('G-PF61178R7Y');

        // Track the initial page view
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

        fetch('/data.json')
            .then(response => response.json())
            .then(data => {
                setItems(data.groceryItems);
            });

        fetch('/measureUnits.json')
            .then(response => response.json())
            .then(data => {
                setMeasureUnits(data);
            });

        fetch('/itemNames.json')
            .then(response => response.json())
            .then(data => {
                setItemNames(data);
            });

        fetch('/categories.json')
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            });

        fetch(`/locales/${language}.json`)
            .then(response => response.json())
            .then(data => setLocalization(data));

        fetch('/brandNames.json')
            .then(response => response.json())
            .then(data => setBrandNames(data));

        fetch('/brandGroups.json')
            .then(response => response.json())
            .then(data => setBrandGroups(data));

        const firstTime = localStorage.getItem('firstTimeUser');
        /*if (!firstTime) {
            setShowGuide(true);
            localStorage.setItem('firstTimeUser', 'false'); // Mark the guide as shown
        }*/
    }, [language]);

    const handleLanguageChange = (newLanguage) => {
        // Track button click event
        ReactGA.event({
            category: 'User Interaction',
            action: 'Language Dropdown Clicked',
            label: `Language Changed ${newLanguage}`,
            language: `${newLanguage}`
        });
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage); // Save the selected language in localStorage
    };

    const handleCheckboxChange = (item, selectedUnit) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Item Checkbox Checked',
            label: `Item Checked/unchecked ${item.name}`,
            item_name: `${item.name}`
        });
        const baseName = item.name; // Use the English name or any unique identifier as the base name
        if (selectedItems[baseName]) {
            handleDeleteItem(baseName);
        } else {
            setSelectedItems(prev => ({
                ...prev,
                [baseName]: {
                    quantity: item.measureUnits[selectedUnit],
                    unit: selectedUnit,
                    stepQuantity: item.measureUnits[selectedUnit],
                    brand: brandGroups[item.brandGroup]?.sponsored || null
                }
            }));
        }
    };

    const handleQuantityChange = (baseName, delta) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Quantity Selector Button Clicked',
            label: `Quantity Changed for ${baseName}`,
            item_name: `${baseName}`
        });
        setSelectedItems(prev => {
            const updatedQuantity = Math.max(prev[baseName].stepQuantity, prev[baseName].quantity + delta);
            return {
                ...prev,
                [baseName]: { ...prev[baseName], quantity: updatedQuantity }
            };
        });
    };

    const handleUnitChange = (item, newUnit) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Unit Dropdown Clicked',
            label: `Unit Changed for ${item.name} with ${newUnit}`,
            item: `${item.name}`,
            unit: `${newUnit}`
        });
        const baseName = item.name; // Use the English name or any unique identifier as the base name
        handleCheckboxChange(item, newUnit);
        setSelectedItems(prev => ({
            ...prev,
            [baseName]: {
                quantity: item.measureUnits[newUnit],
                unit: newUnit,
                stepQuantity: item.measureUnits[newUnit]
            }
        }));
    };

    const handleBrandChange = (baseName, newBrand) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Brand Dropdown Clicked',
            label: `Brand Changed for ${baseName} ${newBrand}`
        });
        setSelectedItems(prev => ({
            ...prev,
            [baseName]: { ...prev[baseName], brand: newBrand }
        }));
    };

    const handleDeleteItem = (baseName) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Item Delete Button Clicked',
            label: `Item deleted ${baseName}`
        });
        const newSelectedItems = { ...selectedItems };
        delete newSelectedItems[baseName];
        setSelectedItems(newSelectedItems);
    };

    const toggleCategoryCollapse = (category) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Category Collpased',
            label: `Category collapsed ${category}`
        });
        setCollapsedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    const filteredItems = items
        .flatMap(category => category.items.map(item => ({
            category: category.category,
            ...item
        })))
        .filter(item => {
            const itemName = itemNames[item.name]?.[language] || itemNames[item.name]?.en || item.name;
            return String(itemName).toLowerCase().includes(searchTerm.toLowerCase());
        });

    const handleShare = () => {
        // Track button click event
        ReactGA.event({
            category: 'User Interaction',
            action: 'WhatsApp Share Button Clicked',
            label: 'WhatsApp Share Button'
        });

        ReactGA.event({
            category: 'Shopping List',
            action: 'List Finalized',
            label: `Finalized List With Item Count: ${selectedItems.length}`,
            item_count: `${selectedItems.length}`,
            nonInteraction: true
        });

        const list = Object.entries(selectedItems).map(([baseName, { quantity, unit, brand }]) => {
            const displayName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
            const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
            return `${displayName} ${brandName != null ? brandName : ''}: ${quantity} ${measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}`;
        }).join('\n');
        const message = list + (enableWhatsAppFooter ? `\n${localization.whatsappFooter}` : '');
        const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    const handleCopy = () => {
        // Track button click event
        ReactGA.event({
            category: 'User Interaction',
            action: 'Clicked on Copy Button',
            label: 'Copy Button'
        });
        const list = Object.entries(selectedItems).map(([baseName, { quantity, unit, brand }]) => {
            const displayName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
            const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
            return `${displayName} ${brandName != null ? brandName : ''}: ${quantity} ${measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}`;
        }).join('\n');
        navigator.clipboard.writeText(list);
        setShowListCopiedAlert(true);
        setTimeout(() => setShowListCopiedAlert(false), 3000);
    };

    const handleClearList = () => {
        // Track button click event
        ReactGA.event({
            category: 'User Interaction',
            action: 'Clear List Button Clicked',
            label: `Clear List Button With Item Count: ${selectedItems.length}`
        });
        setSelectedItems({});
        setShowListClearedAlert(true);
        setTimeout(() => setShowListClearedAlert(false), 3000);
    };

    const handleOverlayToggle = (event) => {
        setTarget(event.target);
        setShowOverlay(!showOverlay);
    };

    const handleNextStep = () => {
        if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            //setShowGuide(false);
        }
    };

    const scrollToList = (event) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Go to list button clicked',
            label: `Go to list button clicked With Item Count: ${selectedItems.length}`
        });
        const listIcon = document.getElementById('selectedItemsList'); // Replace with your actual list icon ID
        if (listIcon) {
            listIcon.scrollIntoView({ behavior: 'smooth' });
            setTarget(listIcon);
            setShowOverlay(true);
        }
    }

    return (
        <Container ref={ref}>
            {showListClearedAlert && <Alert variant="success">{localization.listCleared}</Alert>}
            {showListCopiedAlert && <Alert variant="success">{localization.copySuccess}</Alert>}
            <Row className="my-4">
                <Col>
                </Col>
                <Col xs="auto">
                    <DropdownButton
                        id="language-dropdown"
                        title={localization.language ? `${localization.language}: ${language === 'en' ? 'English' : 'Hindi'}` : 'Select Language'}
                        onSelect={handleLanguageChange}
                    >
                        <Dropdown.Item eventKey="en">English</Dropdown.Item>
                        <Dropdown.Item eventKey="hi">Hindi</Dropdown.Item>
                    </DropdownButton>
                </Col>
                <Col xs="auto">
                    <Button id="selectedItemsList" variant="light" onClick={handleOverlayToggle}>
                        <FaList style={{ fontSize: '24px' }} />
                        <span className="circle-badge">
                            {Object.keys(selectedItems).length}
                        </span>
                    </Button>
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                    {enableSearch && (
                        <Form.Control type="text" placeholder={localization.searchPlaceholder} onChange={e => setSearchTerm(e.target.value)} />
                    )}
                    <h1><strong>{localization.pageheading}</strong></h1>
                    <p>{localization.grocerylistinfo}</p>
                </Col>
            </Row>
            {items.map(category => {
                const categoryName = categories[category.category]?.[language] || categories[category.category]?.en || category.category;
                const isCollapsed = collapsedCategories[category.category];

                return (
                    <div key={category.category} id={category.category} className="mb-3">
                        <h5 onClick={() => toggleCategoryCollapse(category.category)} style={{ cursor: 'pointer' }}>
                            <strong>{isCollapsed ? <FaChevronRight /> : <FaChevronDown />} {categoryName}</strong>
                        </h5>
                        <Collapse in={!isCollapsed}>
                            <div>
                                {category.items.map(item => {
                                    const itemName = itemNames[item.name]?.[language] || itemNames[item.name]?.en || item.name;
                                    const baseName = item.name; // Use this as a consistent key in selectedItems
                                    const selectedItem = selectedItems[baseName];
                                    const selectedUnit = selectedItem ? selectedItem.unit : item.defaultMeasureUnit;
                                    const measureUnit = measureUnits[selectedUnit]?.[language] || measureUnits[selectedUnit]?.en || selectedUnit;
                                    const brandGroup = brandGroups[item.brandGroup];
                                    const selectedBrand = selectedItem?.brand || brandGroup?.sponsored;
                                    return (
                                        <div key={item.name} className="mb-3">
                                            <Form.Check
                                                type="checkbox"
                                                id={itemName}
                                                label={itemName}
                                                checked={!!selectedItems[baseName]}
                                                onChange={() => handleCheckboxChange(item, selectedUnit)}
                                            />
                                            {selectedItems[baseName] && (
                                                <InputGroup className="mt-2">
                                                    <DropdownButton
                                                        as={InputGroup.Prepend}
                                                        variant="outline-secondary"
                                                        title={measureUnit}
                                                        id="input-group-dropdown-1"
                                                        onSelect={(unit) => handleUnitChange(item, unit)}
                                                    >
                                                        {Object.keys(item.measureUnits).map(unit => (
                                                            <Dropdown.Item key={unit} eventKey={unit}>
                                                                {measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </DropdownButton>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => handleQuantityChange(baseName, -selectedItems[baseName].stepQuantity)}
                                                    >
                                                        -
                                                    </Button>
                                                    <Form.Control
                                                        type="text"
                                                        value={selectedItems[baseName].quantity}
                                                        readOnly
                                                        style={{ maxWidth: '60px', textAlign: 'center' }}
                                                    />
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => handleQuantityChange(baseName, selectedItems[baseName].stepQuantity)}
                                                    >
                                                        +
                                                    </Button>
                                                    {showBrandDropdown && brandGroup && (
                                                        <DropdownButton
                                                            as={InputGroup.Prepend}

                                                            variant="outline-secondary"
                                                            title={brandNames[selectedBrand]?.[language] || brandNames[selectedBrand]?.en || selectedBrand}
                                                            id="input-group-dropdown-2"
                                                            onSelect={(brand) => handleBrandChange(baseName, brand)}
                                                        >
                                                            {brandGroup.brands.map((brandObj, index) => {
                                                                return (
                                                                    <Dropdown.Item key={index} eventKey={brandObj}>
                                                                        {brandNames[brandObj]?.[language] || brandNames[brandObj]?.en || brandObj}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </DropdownButton>
                                                    )}
                                                    <Button variant="danger" onClick={() => handleDeleteItem(baseName)}>{localization.delete}</Button>
                                                </InputGroup>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </Collapse>
                    </div>
                );
            })}
            <Row className="my-4">
                <Col xs="auto">
                    <Button className="sticky-btn" onClick={scrollToList}>{localization.gotolist}</Button>
                </Col>
            </Row>

            {showGuide && steps[stepIndex] && (
                <Overlay target={document.querySelector(steps[stepIndex].target)} show={true} placement="bottom">
                    <Popover id="guide-popover">
                        <Popover.Body>
                            {steps[stepIndex].content}
                            <Button className="mt-2" onClick={handleNextStep}>
                                {stepIndex < steps.length - 1 ? 'Next' : 'Finish'}
                            </Button>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            )}
            <Overlay style={{ position: 'fixed', top: '10%', right: '10%', zIndex: 1040 }} show={showOverlay} target={target} placement="bottom" container={ref.current} containerPadding={20} onHide={() => setShowOverlay(false)} className="sticky-overlay">
                <Popover id="popover-contained">
                    <Popover.Header as="h3"><strong>{localization.yourList}</strong></Popover.Header>
                    <Popover.Body>
                        {Object.keys(selectedItems).length > 0 ? (
                            <ul className="list-group">
                                {Object.entries(selectedItems).map(([baseName, { quantity, unit, brand }]) => {
                                    const itemName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
                                    const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
                                    return (
                                        <li key={baseName} className="list-group-item d-flex justify-content-between align-items-center">
                                            <span>{itemName} {brandName}: {quantity} {measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}</span>
                                            <Button variant="danger" size="sm" className="ml-auto" onClick={() => handleDeleteItem(baseName)}>{localization.delete}</Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <p>{localization.noItems}</p>
                        )}
                        <Button className="mt-3 btn-success" disabled={Object.keys(selectedItems).length === 0} onClick={handleShare}><FaWhatsapp />{localization.share}</Button>
                        <Button className="mt-3" disabled={Object.keys(selectedItems).length === 0} onClick={handleCopy}><FaRegCopy />{localization.copy}</Button>
                        <Button className="mt-3 btn-danger" variant="secondary" disabled={Object.keys(selectedItems).length === 0} onClick={handleClearList}><FaTrash />{localization.clearList}</Button>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </Container>
    );
}

export default App;
